<template>
    <span>
        <a v-if="directLink" :href="dl" ref="dl" :target="target" :class="{'smfm-externallink': isPro, 'smfm-locallink': !isPro}"
           :title="linkTitle">
            <slot></slot>
        </a>
        <a v-else :href="dl" @click.prevent.stop="openPopup" class="smfm-locallink" :title="linkTitle">
            <slot></slot>
        </a>

        <simple-dialog :title="articleobj.title" :show-cancel="false" :show-close="true" ref="dialog">
            <div v-html="articleobj['summary' + variant]"></div>
            <template v-slot:buttons="{ close }">
                <v-btn text color="secondary" class="me-5" @click="close">{{ $t('app.cancel') }}</v-btn>
                <a :href="dl" :target="target" @click="close">
                    <v-btn class="smfm-primary-btn smfm-lg-btn">
                        {{ $t('app.readmore') }}
                        <!-- <v-icon v-if="isPro">{{ iconOpenLink }}</v-icon> -->
                    </v-btn>
                </a>
            </template>
        </simple-dialog>
    </span>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiOpenInNew } from "@mdi/js";
    import SimpleDialog from "@/components/forms/SimpleDialog";

    export default {
        name: "ArticleLink",
        components: { SimpleDialog },
        props: {
            article: null,
            variant: { default: '' },
            directLink: { default: false },
            disabled: {
                type: Boolean,
                default: false
            },
            showTitle: {default: false},
        },
        computed: {
            ...mapGetters(['resourcesToc']),
            iconOpenLink() { return mdiOpenInNew },
            articleobj() {
                return this.resourcesToc ? this.getArticleFromToc(this.article, this.currentLang, {}) : {}
            },
            dl() {
                return this.resourcesToc ? `${this.appUrl}/`+this.getArticleUrl(this.article, this.currentLang, '#') : '#'
            },
            linkTitle() {
                return this.showTitle && this.articleobj.title ? this.articleobj.title : ''
            },
            target() {
                return this.isPro ? '_blank' : '_self'
            }
        },
        created() {
            this.loadResourcesToc()
        },
        methods: {
            ...mapActions(['loadResourcesToc']),
            openPopup() {
                if (!this.disabled) {
                    this.loadResourcesToc()
                        .then(() => this.$refs.dialog.open())
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>