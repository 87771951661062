<template>
    <div>
        <v-textarea outlined rows="4" :label="$t('app.forms.yourtext')" name="text" v-model="text"
                    :rules="[requiredRule]"
                    :prepend-icon="iconText"
                    @change="$emit('change')"></v-textarea>
    </div>
</template>

<script>
    import { mdiTextBoxOutline } from "@mdi/js";
    import validation from "@/mixins/validation";

    export default {
        mixins: [ validation ],
        data() {
            return {
                text: ""
            };
        },
        computed: {
            qrdata() {
                return {
                    data: this.text,
                    label: this.text
                }
            },
            value: {
                get() {
                    return {
                        text: this.text,
                    }
                },
                set(newValue) {
                    this.text = newValue.text
                }
            },
            iconText() { return mdiTextBoxOutline }
        }
    }
</script>
