<template>
    <div class="d-inline">
        <slot :proceed="proceed"></slot>
        <simple-dialog title="ScanMeFindMe PRO" @save="agree" :show-cancel="false" ref="showlicense">
                <div v-html="prepareTextWithLinks($t('app.launchpro'))"></div>
                <template v-slot:buttons="{save}">
                    <v-btn class="smfm-primary-btn smfm-lg-btn" @click="save">
                        {{ $t('app.acceptgotopro') }}
                    </v-btn>
                </template>
        </simple-dialog>

        <simple-dialog title="ScanMeFindMe PRO" ref="comingsoon" :show-cancel="false" :show-close="false">
            <p class="text-h6">Coming soon...</p>
            <template v-slot:buttons="{close}">
                <v-btn class="smfm-primary-btn smfm-lg-btn" @click="close">
                    {{ $t('app.cancel') }}
                </v-btn>
            </template>
        </simple-dialog>
    </div>
</template>

<script>
    import SimpleDialog from "@/components/forms/SimpleDialog";

    export default {
        name: "DemoLaunchPro",
        components: {SimpleDialog},
        props: {
            force: {default: false},
        },
        methods: {
            proceed() {
                if (!this.force && this.isPrivacyAccepted()) {
                    this.goToPro()
                } else {
                    this.$refs.showlicense.open()
                }
            },
            agree() {
                this.dialog = false
                this.acceptPrivacy()
                this.goToPro()
            },
            goToPro() {
                if (process.env.VUE_APP_BLOCK_PRO) {
                    this.$refs.comingsoon.open()
                } else {
                    window.location.href = this.proUrl + '?lang=' + this.currentLang
                }
            }
        }
    }
</script>

<style scoped>

</style>