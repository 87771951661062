<template>
    <v-card flat class="element mx-1" @click="$emit('input', option.value)" :class="{ 'highlightactive' : !asbutton }">
        <v-badge
            :bordered="option.withbadge"
            :color="option.withbadge ? 'green darken-2' : 'transparent'"
            class="d-flex align-center justify-center flex-column"
            :style="`width: ${size}px`"
            overlap dot>
            <div :style="getStyle(option)[2]" class="d-flex align-center justify-center flex-column smfm-h-100 smfm-w-100"
                 v-intersect="()=>loadImage(option, idx)">
                <div v-if="option.label" class="option-by-label" v-html="option.label" @load="loadedImage(option)"></div>
                <v-img v-else-if="option.previewsrc" :src="option.previewsrc"
                       class="option-by-previewsrc"
                       :alt="option.value"
                       :height="getStyle(option)[1]"
                       :width="getStyle(option)[0]"
                       @load="loadedImage(option)">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center flex-column smfm-h-100 smfm-w-100 image-preload" :style="getStyle(option)[3]">
                            <v-progress-circular color="#1867c0" size="24" indeterminate :aria-label="$t('app.loading')"></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <v-img v-else-if="option.src" :src="option.src"
                       class="option-by-src"
                       :alt="option.value"
                       :height="getStyle(option)[1]"
                       :width="getStyle(option)[0]"
                       @load="loadedImage(option)">
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center flex-column smfm-h-100 smfm-w-100 image-preload" :style="getStyle(option)[3]">
                            <v-progress-circular color="#1867c0" size="24" indeterminate :aria-label="$t('app.loading')"></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <div v-else class="d-flex align-center justify-center flex-column smfm-h-100 smfm-w-100 image-preload" :style="getStyle(option)[3]">
                    <v-progress-circular color="#1867c0" size="24" indeterminate :aria-label="$t('app.loading')"></v-progress-circular>
                </div>
            </div>
        </v-badge>
    </v-card>
</template>

<script>
    export default {
        name: "ImageselectElement",
        props: {
            option: { default: {} },
            idx: { default: 0 },
            size: { default: 60 },
            asbutton: { default: false }
        },
        methods: {
            getStyle(metadata) {
                if (metadata.label) {
                    return [this.size, this.size, '', '']
                }
                if (!metadata.value || metadata.value === '0') {
                    return [30, 30, `padding: ${this.size/2 - 15}px`, '']
                }
                if (!metadata.src && !metadata.previewsrc) {
                    const m = (this.size-24)/2
                    return [this.size, this.size, `width:${this.size}px; height:${this.size}px`, `padding: ${m}px`]
                }

                let ratio = (metadata.width > 0 && metadata.height > 0) ? metadata.height / metadata.width : 1,
                    width = (ratio > 1) ? this.size / ratio : this.size, height = width * ratio
                width = Math.round(width*10)/10
                height = Math.round(height*10)/10
                const left = Math.round((this.size-width)/2*10)/10, right = this.size - left - width,
                      top = Math.round((this.size-height)/2*10)/10, bottom = this.size - top - height
                return [width, height, `padding: ${top}px ${right}px ${bottom}px ${left}px; width:${this.size}px; height:${this.size}px`, '']
            },
            loadImage(option) {
                if (option && option.loadcontent) option.loadcontent()
            },
            loadedImage() {
                // TODO: finish loading when we have image from back
                // return this.data = this.options.map((el) => {
                //     if (el === option) {
                //         el.isLoading = false
                //     }
                //     return el
                // })
            },
        },
    }
</script>

<style scoped lang="scss">
.element {
    border: 2px solid transparent;
    line-height: 0;
    &:hover {
        border: 2px solid #12C7D0;
        background: rgba(0, 173, 181, 0.1);
    }
}
.element.highlightactive.elementisactive {
    border: 2px solid #12C7D0;
    background: rgba(0, 173, 181, 0.1);
}

img {
    object-fit: scale-down;
}

</style>