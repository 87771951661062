<template>
    <div>
        <v-radio-group v-model="ttype" dense @change="$emit('change')">
            <v-radio v-for="(typelabel, type) in ttypes" :label="typelabel" :value="type" :key="'fbtype_'+type"></v-radio>
        </v-radio-group>
        <div class="mt-1" v-if="isProfile">
            <v-text-field outlined name="profile" :label="$t('app.forms.username')" v-model="profile"
                          :rules="profileRules" :prepend-icon="iconFacebook" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div class="mt-1" v-else>
            <v-text-field outlined name="url" :label="$t('app.forms.urltoshare')" v-model="url"
                          :rules="urlRules" :prepend-icon="iconUrl" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
    </div>
</template>

<script>
    import { mdiWeb } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";
    import customIcons from "@/mixins/customIcons";

    export default {
        mixins: [ validation, dmf, customIcons ],
        data() {
            return {
                ttype: "link",
                profile: "",
                url: "",
                ttypes: {link: this.$t('app.forms.fbprofile'), me: this.$t('app.forms.fbmessage'), url: this.$t('app.forms.fbshare')}
            };
        },
        computed: {
            isProfile() {
                return this.ttype === 'link' || this.ttype === 'me'
            },
            value: {
                get() {
                    return {
                        ttype: this.ttype,
                        profile: this.profile,
                        url: this.url,
                    }
                },
                set(newValue) {
                    this.ttype = newValue.ttype
                    this.profile = newValue.profile
                    this.url = newValue.url
                }
            },
            profileRules() {
                return this.isProfile ? [this.requiredRule] : []
            },
            urlRules() {
                return !this.isProfile ? [this.requiredRule, this.urlRule] : []
            },
            qrdata() {
                if (this.ttype === 'link') {
                    return {
                        data: 'https://facebook.com/' + this.profile.trim(),
                        label: this.profile.trim()
                    }
                } else if (this.ttype === 'me') {
                    return {
                        data: 'http://m.me/' + this.profile.trim(),
                        label: this.$t('app.forms.fbmessage') + ' ' + this.profile.trim()
                    }
                } else {
                    return {
                        data: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url),
                        label: this.$t('app.forms.fbshare') + ' ' + this.url
                    }
                }
            },
            iconUrl() { return mdiWeb }
        }
    }
</script>

<style scoped>

</style>
