<template>
<div>
    <div class="d-flex flex-row color-picker" ref="colorpicker">
        <span class="input-group-addon d-flex align-center">
            <div class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker"></div>
            <v-color-picker v-if="displayPicker" elevation="4" v-model="pickercolor"></v-color-picker>
        </span>
        <v-text-field
            outlined
            dense
            hide-details
            :label="label"
            :name="name"
            :id="idComputed"
            @input="updateColorInPicker"
            @focus="showPicker"
            v-model="colorValue"
            ref="myinput"
            class="ps-2"
        />
    </div>
</div>
</template>


<script>
    import { parseHex } from 'vuetify/lib/util/colorUtils'

    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String
            },
            color: {},
            label: {default: ''},
            value: {default: ''}
        },
        data: function () {
            return {
                colorValue: '#000000',
                colors: null,
                displayPicker: false,
                hexa: '#000000FF',
            }
        },
        mounted() {
            this.setColor(this.value);
        },
        computed: {
            idComputed: function() {
                return (this.id || 'inpcolorid_'+this.name).replace(/[^\w]/g, '');
            },
            pickercolor: {
                get () {
                    return this.hexa
                },
                set (hexa) {
                    this.hexa = hexa
                    if (/^#[A-Fa-f0-9]{6}00$/.test(hexa)) {
                        this.colorValue = 'transparent'
                    } else if (/^#[A-Fa-f0-9]{6}FF$/.test(hexa)) {
                        this.colorValue = hexa.substring(0, 7)
                    } else {
                        this.colorValue = hexa
                    }
                },
            },
        },
        methods: {
            setColor(color) {
                this.colorValue = color;
                this.updateColorInPicker();
            },
            updateColorInPicker() {
                let color = '' + this.colorValue
                if (/^#[A-Fa-f0-9]+$/.test(color)) {
                    this.hexa = parseHex(color)
                } else if (this.colorValue === 'transparent') {
                    this.hexa = '#FFFFFF00'
                // } else if (color.slice(0, 4) === 'rgba') {
                //     const rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(',')
                //     this.rgba = {r: parseInt(rgba[0]), g: parseInt(rgba[1]), b: parseInt(rgba[2]), a: parseFloat(rgba[3])}
                } else {
                    this.hexa = '#000000FF'
                }
            },
            showPicker() {
                document.addEventListener('click', this.documentClick);
                this.displayPicker = true;
            },
            hidePicker() {
                document.removeEventListener('click', this.documentClick);
                this.displayPicker = false;
                this.triggerEvent();
            },
            togglePicker() {
                this.displayPicker ? this.hidePicker() : this.showPicker();
            },
            triggerEvent() {
                this.$emit('input', this.colorValue);
            },
            documentClick(e) {
                var el = this.$refs['colorpicker'],
                    target = e.target;
                if (el !== target && !el.contains(target)) {
                    this.hidePicker()
                }
            }
        },
        watch: {
            value(val) {
                // Set the current color if the v-model was changed from the outside
                if (val !== this.colorValue) {
                    this.setColor(val)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.color-picker {
    width: 160px;
    position: relative;
    input {
        height: 40px;
        width: 185px;
    }
    .v-color-picker {
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 9;
    }
    .current-color {
        width: 40px;
        height: 40px;
        background-color: #000;
        cursor: pointer;
        border: 1px solid #CECECE;
        border-radius: 6px;
    }
}
</style>
