<template>
    <v-row>
        <v-col cols="12" sm="6" class="pa-0">
            <v-text-field outlined name="phonewhatsapp" :label="$t('app.forms.enterphone')" v-model="phone"
                        :rules="[requiredRule, phoneRule]" :prepend-icon="iconWhatsapp" dir="ltr"
                        @change="$emit('change')"></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
    import { mdiWhatsapp } from "@mdi/js";
    import dmf from '../../mixins/dmf';
    import validation from "@/mixins/validation";

    export default {
        mixins: [ validation, dmf ],
        data() {
            return {
                phone: ""
            };
        },
        computed: {
            qrdata() {
                return {
                    data: 'https://wa.me/' + this.escape_phone(this.phone, true),
                    label: this.phone
                }
            },
            value: {
                get() {
                    return {
                        phone: this.phone,
                    }
                },
                set(newValue) {
                    this.phone = newValue.phone
                }
            },
            iconWhatsapp() { return mdiWhatsapp }
        }
    }
</script>
