<template>
    <div class="qr-preview">
        <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-7">
            <!-- QR thumb -->
            <qr-thumb v-if="hasqr && !inViewport.now && inViewport.below" 
                class="d-md-none" mainel=".demo-qr-image">
            </qr-thumb>
            <!-- QR code image -->
            <div v-if="hasqr" class="card-text demo-qr-image">
                <div v-if="svg" 
                    data-role="svg" v-html="svg"
                    class="d-flex justify-center align-center">
                </div>
                <loading v-if="isloading" :size="3" class="loading"></loading>
            </div>
            <div v-else class="card-text placeholder-qr-image text-center" :style="placeholderStyle">
            </div>
        </v-card>
        <!-- Alerts -->
        <v-alert v-if="isnotvalid"
            prominent :icon="iconError" 
            type="warning" color="#C9454D" class="pa-5 mt-4 white--text">
            {{ $t('app.poorcontrast') }}
        </v-alert>
        <v-alert v-if="error" 
            prominent :icon="iconError"
            type="error" class="pa-5 mt-4 white--text">
            {{ $t('app.apierror') }}
        </v-alert>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import inViewport from "vue-in-viewport-mixin";
    import QrThumb from "@/components/demo/QrThumb";
    import customIcons from "@/mixins/customIcons";
    import Loading from "@/components/common/Loading";

    export default {
        components: { QrThumb, Loading },
        mixins: [ inViewport, customIcons ],
        name: "QrPreview",
        props: {
            iconname: null,
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            svg() { return this.currentqr.image },
            isloading() { return this.currentqr.loading },
            isnotvalid() { return this.currentqr.isnotvalid },
            error() { return this.currentqr.error },
            hasqr() {
                return (this.isloading || this.currentqr.image)
            },
            placeholderStyle() {
                const icon = this.iconname ? this.iconname : 'qr';
                return `background-image: url('/images/placeholders/placeholder_${icon}.svg')`;
            },
        }
    }
</script>

<style lang="scss">
.qr-preview {
    .demo-qr-image > div[data-role=svg] {
        max-width: 100%;
        max-height: 100%;
        position:absolute;
        top: -9999px;
        left: -9999px;
        right: -9999px;
        bottom: -9999px;
        margin: auto;
    }
    .demo-qr-image > div[data-role=svg] > svg {
        width: 100%;
        height: 100%;
    }
    .demo-qr-image > div[role=progressbar] {
        position: absolute;
    }
    .demo-qr-image,
    .placeholder-qr-image {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .placeholder-qr-image {
        background-image: url('/images/placeholders/bg.svg');
    }
    .placeholder-qr-image .v-icon {
        position: absolute;
    }
    .demo-qr-image {
        background-repeat: no-repeat;
        background-position: center;
        .loading {
            position: absolute;
            top: 50%;
            left :50%;
        }
    }
    .v-alert {
        width: 100% !important;
        @media(max-width: 960px) {
            width: calc(100% - 40px) !important;
            margin: 0 auto;
        }
        .v-alert__content {
            font-size: 18px;
            line-height: 26px;
            @media(max-width: 960px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
        &.v-alert--prominent {
            .v-alert__icon {
                height: 38px;
                min-width: 38px;
                margin-inline-end: 22px;
                .v-icon__svg {
                    color: #C9454D;
                    z-index: 9;
                }
                &:after {
                    background: #fff !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}
</style>
