<template>
    <div>
        <p class="font-weight-medium">{{ $t('app.forms.cryptocurrency') }}</p>
        <v-radio-group v-model="crypto" dense @change="$emit('change')">
            <v-radio v-for="(typelabel, type) in ctypes" :label="typelabel" :value="type" :key="'ctype_'+type"></v-radio>
        </v-radio-group>
        <div v-if="crypto === 'other'">
            <v-text-field outlined name="cryptoname" :label="$t('app.forms.currencyname')" v-model="cryptoname"
                          :rules="nameRequiredRules" :prepend-icon="iconCrypto" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div>
            <v-text-field outlined name="cryptoaddress" :label="$t('app.forms.cryptoaddress')" v-model="cryptoaddress"
                          :rules="[requiredRule]" :prepend-icon="iconWallet" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div>
            <v-text-field outlined name="cryptoamount" :label="$t('app.forms.amount')" v-model="cryptoamount"
                          :prepend-icon="iconAmount" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
    </div>
</template>

<script>
    import { mdiAlphaCCircleOutline, mdiCashMultiple, mdiWalletOutline } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";

    export default {
        mixins: [ validation, dmf ],
        computed: {
            isOther() {
                return this.crypto === 'other'
            },
            nameRequiredRules() {
                return this.isOther ? [this.requiredRule] : []
            },
            value: {
                get() {
                    return {
                        cryptoaddress: this.cryptoaddress,
                        crypto: this.crypto,
                        cryptoamount: this.cryptoamount,
                        cryptoname: this.cryptoname,
                    }
                },
                set(newValue) {
                    for (let k in newValue) {
                        this.$set(this, k, newValue[k])
                    }
                }
            },
            qrdata() {
                var prefix = this.crypto + ':', postfix = ''
                var label = this.ctypes[this.crypto]
                if (this.isOther) {
                    prefix = this.cryptoname.replace(/[^\w]/, '').toLowerCase() + ':'
                    label = this.cryptoname
                }
                if (this.cryptoamount) {
                    postfix = '?amount=' + encodeURIComponent(this.cryptoamount)
                }

                return {
                    data: prefix + this.cryptoaddress + postfix,
                    label: label + ' ' + this.cryptoaddress
                }
            },
            iconCrypto() { return mdiAlphaCCircleOutline },
            iconWallet() { return mdiWalletOutline },
            iconAmount() { return mdiCashMultiple },
        },

        data() {
            return {
                cryptoaddress: "",
                crypto: "bitcoin",
                cryptoamount: "",
                cryptoname: "",
                ctypes: {
                    'bitcoin': 'Bitcoin',
                    'ethereum': 'Ethereum',
                    // 'bitcoincash': 'Bitcoin cash',
                    // 'litecoin': 'Litecoin',
                    // 'nano': 'Nano',
                    'other': this.$t('app.forms.other')+'...',
                }
            };
        }
    }
</script>
