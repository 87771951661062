<template>
    <div>
        <v-card  flat tile>
            <p class="font-weight-medium">{{ $t('app.forms.encryption') }}</p>
            <v-radio-group row v-model="encryption" dense @change="$emit('change')">
                <v-radio v-for="(typelabel, type) in etypes" :label="typelabel" :value="type" :key="'etype_'+type"></v-radio>
            </v-radio-group>
            <v-checkbox dense name="ishidden" :label="$t('app.forms.wifihidden')"
                v-model="ishidden" 
                class="mt-0"
                @change="$emit('change')">
            </v-checkbox>
        </v-card>
        <v-row>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                <v-text-field outlined name="ssid" label="SSID" v-model="ssid"
                            :rules="[requiredRule]" :prepend-icon="iconWifi" dir="ltr"
                            @change="$emit('change')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']" v-if="encryption !== 'NONE'">
                <v-text-field outlined name="wifipassword" :label="$t('app.forms.wifipassword')" v-model="password"
                            :prepend-icon="iconPassword" dir="ltr"
                            @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mdiKeyVariant, mdiWifi } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";

    export default {
        mixins: [ validation, dmf ],
        data() {
            return {
                ssid: "",
                password: "",
                ishidden: "",
                encryption: "WPA",
                etypes: {WPA: 'WPA/WPA2', WEP: 'WEP', NONE: this.$t('app.forms.wifinone')}
            };
        },
        computed: {
            value: {
                get() {
                    return {
                        ssid: this.ssid,
                        password: this.password,
                        ishidden: this.ishidden,
                        encryption: this.encryption,
                    }
                },
                set(newValue) {
                    this.ssid = newValue.ssid
                    this.password = newValue.password
                    this.ishidden = newValue.ishidden
                    this.encryption = newValue.encryption
                }
            },
            qrdata() {
                let encryption = this.encryption ? this.encryption : '';
                let password = this.password.trim()
                if (!password.length) {
                    encryption = '';
                } else if (encryption !== 'WPA' && encryption !== 'WEP') {
                    encryption = 'WPA';
                }

                return {
                    data: this.dmf('WIFI', {T: encryption, S: this.ssid, P: password, H: this.ishidden ? 'true' : '' }, ['H']),
                    // data: 'WIFI:' +
                    //     this.dmfescape('T',  encryption) + ';' +
                    //     this.dmfescape('S',  this.ssid) + ';' +
                    //     this.dmfescape('P', password) + ';' +
                    //     this.dmfescape('H', this.ishidden ? 'true' : '', true) + ';',
                    label: this.ssid
                }
            },
            iconWifi() { return mdiWifi },
            iconPassword() { return mdiKeyVariant }
        }
    }
</script>
