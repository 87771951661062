<template>
    <v-radio-group :dense="dense" :label="label" :name="name" 
        @change="radioChanged" :value="value" class="mt-0">
        <v-radio v-for="(typelabel, type) in options" :label="typelabel" 
            :value="type" :key="'ftype_'+type" :ref="'fmt'+type">
        </v-radio>
    </v-radio-group>
</template>

<script>
    export default {
        props: {
            name: {},
            label: {},
            options: {},
            dense: {default: false},
            value: {}
        },
        data: function() {
            return {
                localvalue: 0
            }
        },
        mounted: function() {
        },
        methods: {
            radioChanged: function(val) {
                // console.log('radiochanged')
                // console.log(val)
                // console.log(this.$refs['fmt'+val])
                // if (this.$refs['fmt'+val]) {
                //     console.log(this.$refs['fmt'+val][0].$refs.input)
                //     this.$refs['fmt'+val][0].$refs.input.dispatchEvent(new Event("change", {bubbles: true}));
                // }
                this.$emit('input', val);
            }
        }
    }
</script>
