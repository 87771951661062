<template>
    <div v-if="options">
        <div v-if="label" class="carousel-label">
            <div v-html="label"></div>
        </div>
        <v-sheet>
            <v-slide-group ref="slider" multiple mobile-breakpoint="960" active-class="elementisactive" v-model="items"
                :show-arrows="$vuetify.breakpoint.mdAndUp">
                <div v-for="(option, idx) in options" :key="`div-${option.value}${option.previewsrc}`">
                    <v-slide-item v-slot:default="{  }" :key="`si-${option.value}${option.previewsrc}`"
                        :value="idx" :ref="'el'+idx">
                        <imageselect-element :option="option" :idx="idx"
                                             :size="size" :asbutton="asbutton"
                                             @input="v=>$emit('input', v)">
                        </imageselect-element>
                    </v-slide-item>
                </div>
            </v-slide-group>
        </v-sheet>
    </div>
</template>

<script>
    // This component is similar to ImageselectSortable but does not allow sorting
    import ImageselectElement from "@/components/forms/ImageselectElement";

    export default {
        props: {
            options: {
                type: Array,
                default: () => []
            },
            name: { type: String },
            label: { default: '' },
            value: {
                get() {
                    return (this.items.length && this.options.length) ? this.options[this.items[0]].value : null
                },
                set(v) {
                    this.__setValue(v)
                }
            },
            asbutton: { default: false },
            size: { default: 60 },
        },
        components: { ImageselectElement },
        data() {
            return {
                items: [],
            }
        },
        mounted() {
            this.__setValue(this.value)
        },
        computed: {
            dataset() {
                return this.options && this.options.length && this.items && this.items.length && this.options[this.items[0]]
            }
        },
        methods: {
            loadImage(option) {
                // TODO duplicated
                if (option && option.loadcontent) option.loadcontent()
            },
            reposition() {
                this.$nextTick(() => {
                    // TODO this does not center the selected element any more
                    this.$refs.slider.setWidths()
                    this.$forceUpdate()
                })
            },
            __setValue(v) {
                this.items = [...Array(this.options.length).keys()].filter(idx => this.options[idx].value === v)
            },
        },
        watch: {
            value(v) {
                this.__setValue(v)
            },
            options(v, oldv) {
                if (v && v.length) {
                    // List of options changed after the render, we probably uploaded or modified something,
                    // v-intersect will not work in this case, load images now.
                    v.forEach(e => this.loadImage(e))
                }
                this.$nextTick(() => {
                    this.$refs.slider.setWidths()
                    this.$refs.slider.genWrapper()
                    // TODO the vuetify element does not recalculate the scroll offset automatically.
                    if (this.$refs.slider.scrollOffset > 0 && (!oldv || !v || oldv.length > v.length)) {
                        this.$refs.slider.scrollOffset = 0
                    }
                    this.$forceUpdate()
                })
            },
        },
    }
</script>

<style scoped lang="scss">
/*! purgecss start ignore */
.carousel-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #393E46;
    padding-left: 10px;
    margin-bottom: 10px;
}
/*! purgecss end ignore */
</style>
