<template>
    <v-card class="demo-qr-thumb pa-5">
        <div class="d-flex justify-space-around">
            <!-- <v-btn depressed text class="demo-qr-image-thumb" @click="scrollToQr" height="50px" bac>
                <div v-if="svg" data-role="svg" v-html="svg"></div>
            </v-btn> -->
            <v-btn 
                x-large block
                class="smfm-primary-btn"
                name="submit" 
                @click="scrollToQr">
                <loading v-if="isloading"></loading>
                <span v-else>{{ $t('app.viewqr') }}</span>
            </v-btn>
        </div>
    </v-card>
</template>

<script>
    import { mapGetters } from "vuex";
    import Loading from "@/components/common/Loading";

    export default {
        name: "QrThumb",
        components: { Loading },
        props: {
            mainel: null
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            svg() { return this.currentqr.image },
            isloading() { return this.currentqr.loading },
        },
        methods: {
            scrollToQr() {
                this.$vuetify.goTo(this.mainel)
            }
        }
    }
</script>

<style scoped lang="scss">
.demo-qr-thumb {
    /* Quick preview for small screens!*/
    min-height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: white;
    z-index: 99;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    div[data-role=svg] {
        height: 50px;
        max-width: 50px;
    }
    div[data-role=svg] > svg {
        max-width: 100%;
        max-height: 100%;
    }
    .demo-qr-image-thumb {
        background-size: 50px 50px;
        width: 50px;
        height: 50px;
    }
    .smfm-primary-btn {
        background: #03949A !important;
    }
}
</style>
