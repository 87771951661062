<template>
    <div class="d-flex justify-center align-center">
        <div class="loading-icon-wrapper" :class="`transform-${size}`">
            <v-icon v-if="step !== 1" class="top-left">{{topleft}}</v-icon>
            <v-icon v-if="step !== 2" class="top-right">{{topright}}</v-icon>
            <v-icon v-if="step !== 3" class="bottom-right">{{bottomright}}</v-icon>
            <v-icon v-if="step !== 0" class="bottom-left">{{bottomleft}}</v-icon>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        props: {
            size: {
                type: Number,
                default: 1,
            },
        },
        data: () => ({
            step: 0,
            animationdelay: 200,
        }),
        computed: {
            topleft() {
                return "M 10.947 0 L 10.947 10.947 L 0 10.947 L 0 0 Z M 2.105 8.842 L 8.842 8.842 L 8.842 2.105 L 2.105 2.105 Z M 4.211 4.211 L 6.737 4.211 L 6.737 6.737 L 4.211 6.737 Z"
            },
            bottomleft() {
                return "M 10.947 13.053 L 10.947 24 L 0 24 L 0 13.053 Z M 2.105 21.895 L 8.842 21.895 L 8.842 15.158 L 2.105 15.158 Z M 4.211 17.263 L 6.737 17.263 L 6.737 19.789 L 4.211 19.789 Z"
            },
            topright() {
                return "M 24 0 L 24 10.947 L 13.053 10.947 L 13.053 0 Z M 15.158 8.842 L 21.895 8.842 L 21.895 2.105 L 15.158 2.105 Z M 17.263 4.211 L 19.789 4.211 L 19.789 6.737 L 17.263 6.737 Z"
            },
            bottomright() {
                return "M 17.263 21.474 L 19.789 21.474 L 19.789 24 L 17.263 24 Z M 15.579 24 L 13.053 24 L 13.053 13.053 L 19.789 13.053 L 19.789 17.263 L 21.474 17.263 L 21.474 13.053 L 24 13.053 L 24 19.789 L 17.263 19.789 L 17.263 15.579 L 15.579 15.579 Z M 21.474 21.474 L 24 21.474 L 24 24 L 21.474 24 Z"
            },
        },
        created() {
            this.increaseStep()
        },
        methods: {
            increaseStep() {
                this.step = (this.step + 1) % 4
                setTimeout(this.increaseStep, this.animationdelay)
            }
        }
    }
</script>

<style lang="scss">
.loading-icon-wrapper {
    position: relative;
    width: 24px;
    height: 24px;
    span {
        position: absolute !important;
    }
    .top-left {
        top: 0;
        left: 0;
    }
    .top-right {
        top: 0;
        right: 0;
    }
    .bottom-left {
        bottom: 0;
        left: 0;
    }
    .bottom-right {
        bottom: 0;
        right: 0;
    }
    &.transform-2 {
      svg {
        transform: scale3d(2, 2, 2);
      }
    }
    &.transform-3 {
      svg {
        transform: scale3d(3, 3, 3);
      }
    }
}
</style>