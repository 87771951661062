<template>
    <div class="download-format-btn">
        <v-tooltip top :disabled="available">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <v-btn small
                        :class="[textTransform === 'lower' ? 'text-lowercase' : 'text-uppercase']"
                        :disabled="!available"
                        @click.stop="$emit('select', label)">
                        <v-icon v-if="!available" class="me-1">
                            {{ iconLock }}
                        </v-icon>
                        {{ label }}
                    </v-btn>
                </div>
            </template>
            <span>{{ $t('app.download.availableinpro') }}</span>
        </v-tooltip>
    </div>
</template>

<script>
    import { mdiLock } from '@mdi/js';

    export default {
        name: "QrDownloadDialogBtn",
        props: {
            label: {
                type: String,
                default: ''
            },
            available: {
                type: Boolean,
                default: false
            },
            textTransform: {
                type: String,
                default: 'upper'
            },
        },
        computed: {
            iconLock() { return mdiLock },
        },
    }
</script>

<style scoped lang="scss">
.download-format-btn {
    .v-btn {
        min-width: auto;
        background-color: transparent !important;
        border-radius: 6px;
        box-shadow: none;
        padding: 8px 20px;
        margin: 5px;
        .v-icon {
            color: #C7C8CA;
        }
        &.v-btn--disabled.v-btn--has-bg {
            background-color: transparent !important;
            color: #393E46 !important;
        }
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            background-color: #F6F6F6 !important;
        }
        @media(max-width: 960px) {
            height: 40px;
            padding: 8px 15px;
        }
    }
    &.selected {
        .v-btn {
            background-color: #393E46 !important;
            color: #fff; 
        }
    }
}
</style>