<template>
  <div class="d-block d-md-flex justify-space-between align-center pro-wrapper py-5"
    :class="[$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-10']">
    <div class="flex-grow-1 content" :class="{'mb-5': $vuetify.breakpoint.smAndDown}">
      {{ text }}
    </div>
    <div class="d-flex align-center flex-grow-1"
      :class="[$vuetify.breakpoint.smAndDown ? 'justify-space-between' : 'justify-end']">
      <v-btn large class="smfm-exmp-btn me-2" @click="onSeeExampleClick()">
          {{ $t('app.seeexample') }}
      </v-btn>
      <demo-launch-pro>
        <template slot-scope="{proceed}">
          <v-btn large class="smfm-primary-btn" @click="proceed()">
            {{ $t('app.startfreetrial') }}
          </v-btn>
        </template>
      </demo-launch-pro>
    </div>
  </div>
</template>

<script>
    import DemoLaunchPro from "@/components/demo/DemoLaunchPro";

    export default {
        name: "DemoProVersionBlock",
        components: {DemoLaunchPro},
        props: {
            text: {
                type: String,
                default: ''
            },
        },
        methods: {
            onSeeExampleClick() {
                this.$emit('seeExample');
            }
        }
    }
</script>

<style scoped lang="scss">
.pro-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/go-to-pro.svg'), linear-gradient(88.75deg, #21252C 0%, #393E46 100%);
  border-radius: 12px;
  color: #fff;
  .content {
    max-width: 330px;
    margin-inline-end: 10px;
    font-size: 18px;
    line-height: 26px;
    @media(max-width: 960px) {
      max-width: 100%;
      margin-inline-end: 0;
    }
  }
}
</style>