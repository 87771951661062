<template>
    <div>
        <v-text-field outlined name="url" :label="$t('app.forms.enterurl')" v-model="url"
            :rules="[requiredRule, urlRule]" :prepend-icon="iconUrl" dir="ltr"
            @change="$emit('change')">
        </v-text-field>
        <demo-pro-version-block
            v-if="!isPro"
            :text="$t('app.withproversionurl')"
            @seeExample="$refs.exampledialog.showDialog('url')">
        </demo-pro-version-block>
        <about-dynamic-popup ref="exampledialog"></about-dynamic-popup>
    </div>
</template>

<script>
    import { mdiWeb } from '@mdi/js';
    import validation from "@/mixins/validation";
    import DemoProVersionBlock from '@/components/demo/DemoProVersionBlock'
    import AboutDynamicPopup from "@/components/about/AboutDynamicPopup";

    export default {
        mixins: [ validation ],
        components: {AboutDynamicPopup, DemoProVersionBlock },
        data() {
            return {
                url: "",
            };
        },
        computed: {
            qrdata() {
                return {
                    data: this.url,
                    label: this.url
                }
            },
            value: {
                get() {
                    return {
                        url: this.url,
                    }
                },
                set(newValue) {
                    this.url = newValue.url
                }
            },
            iconUrl() { return mdiWeb }
        }
    }
</script>

<style scoped lang="scss">
</style>
