<template>
    <div>
        <div class="d-flex align-center">
            <p class="font-weight-medium mb-0 me-3">{{ $t('app.forms.vcardformat') }}</p>
            <article-link article="about_contactformats" :show-title="true">
                <v-icon>{{ iconInfo }}</v-icon>
            </article-link>
        </div>
        <v-radio-group row v-model="format" dense @change="$emit('change')">
            <v-radio v-for="(typelabel, type) in ftypes" :label="typelabel" :value="type" :key="'ftype_'+type">
            </v-radio>
        </v-radio-group>
        <!-- First name & Last name -->
        <v-row>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                <v-text-field outlined name="firstname" :label="$t('app.forms.firstname')" v-model="firstname"
                              :rules="isMecard ? requiredNameMecardRules : requiredNameVcardRules" :prepend-icon="iconUser"
                              @change="$emit('change')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                <v-text-field outlined name="lastname" :label="$t('app.forms.lastname')" v-model="lastname"
                              :rules="isMecard ? requiredNameMecardRules : requiredNameVcardRules" :prepend-icon="iconUser"
                              @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Organization & job title -->
        <v-row v-if="!isMecard">
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                <v-text-field outlined name="organisation" :label="$t('app.forms.organisation')" v-model="org"
                              :rules="requiredNameVcardRules" :prepend-icon="iconOrganisation"
                              @change="$emit('change')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                <v-text-field outlined name="title" :label="$t('app.forms.jobtitle')" v-model="title"
                              :prepend-icon="iconTitle"
                              @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Phones -->
        <v-row>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                <v-text-field outlined name="phone1" :label="$t('app.forms.mobilephone')" v-model="phone1"
                              :prepend-icon="iconPhone" dir="ltr"
                              @change="$emit('change')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                <v-text-field outlined name="phone2" :label="$t('app.forms.workphone')" v-model="phone2"
                              :prepend-icon="iconPhone" dir="ltr"
                              @change="$emit('change')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pa-0">
                <v-text-field outlined name="phone3" :label="$t('app.forms.otherphone')" v-model="phone3"
                              :prepend-icon="iconPhone" dir="ltr"
                              @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Email -->
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-text-field outlined name="email" :label="$t('app.forms.email')" v-model="email"
                                :rules="[emailRule]" :prepend-icon="iconEmail" dir="ltr"
                                @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Website -->
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-text-field outlined name="url" :label="$t('app.forms.website')" v-model="url"
                                :rules="[urlRule]" :prepend-icon="iconUrl" dir="ltr"
                                @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Address -->
        <v-row v-if="isMecard">
            <v-col cols="12" sm="12" class="pa-0">
                <v-text-field outlined name="address" :label="$t('app.forms.address')" v-model="address"
                                :prepend-icon="iconAddress"
                                @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <!-- Notes -->
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-textarea outlined rows="4" :label="$t('app.forms.notes')" name="notes" v-model="notes"
                            :prepend-icon="iconText"
                            @change="$emit('change')"></v-textarea>
            </v-col>
        </v-row>
        <demo-pro-version-block
            v-if="!isPro"
            :text="$t('app.withproversioncontact')"
            @seeExample="openContactCardDialog">
        </demo-pro-version-block>
        <!-- Example contact card dialog -->
        <about-dynamic-popup ref="exampledialog"></about-dynamic-popup>
    </div>
</template>

<script>
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";
    import ArticleLink from "@/components/forms/ArticleLink";
    import { mdiAccountOutline, mdiAt, mdiBriefcase, mdiDomain, mdiMapMarkerOutline, mdiPhone,
             mdiTextBoxOutline, mdiWeb, mdiInformation } from "@mdi/js";
    import DemoProVersionBlock from '@/components/demo/DemoProVersionBlock'
    import AboutDynamicPopup from "@/components/about/AboutDynamicPopup";

    export default {
        components: {AboutDynamicPopup, ArticleLink, DemoProVersionBlock},
        mixins: [validation, dmf],
        data() {
            return {
                format: 'mecard',
                firstname: "",
                lastname: "",
                address: "",
                notes: "",
                phone1: "",
                phone2: "",
                phone3: "",
                email: "",
                url: "",
                title: '',
                org: '',
                ftypes: {mecard: 'MeCard', vcard: 'vCard'},
            };
        },
        computed: {
            hasName() {
                if (this.format === 'mecard') {
                    return ('' + this.firstname + this.lastname).length > 0
                } else {
                    return ('' + this.firstname + this.lastname + this.org).length > 0
                }
            },
            isMecard() {
                return this.format === 'mecard'
            },
            requiredNameMecardRules() {
                const req = v => !!v || this.$t('app.validation.mecardrequired')
                return this.isMecard && !this.hasName ? [req] : []
            },
            requiredNameVcardRules() {
                const req = v => !!v || this.$t('app.validation.vcardrequired')
                return !this.isMecard && !this.hasName ? [req] : []
            },
            value: {
                get() {
                    return {
                        format: this.format,
                        firstname: this.firstname,
                        lastname: this.lastname,
                        address: this.address,
                        notes: this.notes,
                        phone1: this.phone1,
                        phone2: this.phone2,
                        phone3: this.phone3,
                        email: this.email,
                        url: this.url,
                        title: this.title,
                        org: this.org,
                    }
                },
                set(newValue) {
                    for (let k in newValue) {
                        this.$set(this, k, newValue[k])
                    }
                }
            },
            qrdata() {
                if (this.isMecard) {
                    // https://web.archive.org/web/20160304025131/https://www.nttdocomo.co.jp/english/service/developer/make/content/barcode/function/application/addressbook/index.html
                    return {
                        data: this.dmf('MECARD', {
                            N: [(''+this.lastname).replace(/,/, ' '), (''+this.firstname).replace(/,/, ' ')],
                            TEL__1: this.phone1,
                            TEL__2: this.phone2,
                            TEL__3: this.phone3,
                            EMAIL: this.email,
                            URL: this.url,
                            ADR: this.address,
                            NOTE: this.notes
                        }, ['TEL', 'EMAIL', 'URL', 'ADR', 'NOTE']) + ';',
                        label: '' + this.firstname + ' ' + this.lastname
                    }
                } else {
                    // https://www.npmjs.com/package/vcards-js
                    var card = window.Vcard();
                    card.version = '4.0'
                    card.firstName = this.firstname
                    card.middleName = this.lastname
                    card.cellPhone = this.phone1
                    card.workPhone = this.phone2
                    card.otherPhone = this.phone3
                    card.email = this.email
                    card.url = this.url
                    card.organization = this.org
                    card.title = this.title
                    card.note = this.notes
                    if (!this.firstname && !this.lastname) {
                        card.formattedName = this.org
                    }

                    return {
                        data: card.getFormattedString(),
                        label: this.firstname + ' ' + this.lastname
                    }
                }
            },
            iconUser() { return mdiAccountOutline },
            iconPhone() { return mdiPhone },
            iconEmail() { return mdiAt },
            iconUrl() { return mdiWeb },
            iconAddress() { return mdiMapMarkerOutline },
            iconText() { return mdiTextBoxOutline },
            iconOrganisation() { return mdiDomain },
            iconTitle() { return mdiBriefcase },
            iconInfo() { return mdiInformation }
        },
        methods: {
            openContactCardDialog() {
                this.$refs.exampledialog.showDialog('contact')
            },
        }
    }
</script>
