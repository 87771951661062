<template>
    <div>
        <div class="">
            <v-text-field outlined name="email" :label="$t('app.forms.enteremail')" v-model="email"
                          :rules="[requiredRule, emailRule]" :prepend-icon="iconEmail" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div class="">
            <v-text-field outlined name="subject" :label="$t('app.forms.subject')" v-model="subject"
                          :prepend-icon="iconSubject"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div class="">
            <v-textarea outlined rows="4" :label="$t('app.forms.message')" name="message" v-model="message"
                        :prepend-icon="iconMessage"
                        @change="$emit('change')"></v-textarea>
        </div>
    </div>
</template>

<script>
    import { mdiMessageTextOutline, mdiTagTextOutline, mdiAt } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";

    export default {
        mixins: [ validation, dmf ],
        data() {
            return {
                email: "",
                subject: "",
                message: "",
            };
        },
        computed: {
            value: {
                get() {
                    return {
                        email: this.email,
                        subject: this.subject,
                        message: this.message,
                    }
                },
                set(newValue) {
                    this.email = newValue.email
                    this.subject = newValue.subject
                    this.message = newValue.message
                }
            },
            qrdata() {
                return {
                    data: this.dmf('MATMSG', {TO: this.email, SUB: this.subject, BODY: this.message}, ['SUB', 'BODY']) + ';',
                    // data: 'MATMSG:' + this.escape('TO', this.email) + ';' +
                    //     this.escape('SUB', this.subject, true) + ';' +
                    //     this.escape('BODY', this.message, true) + ';;',
                    label: this.email
                }
            },
            // escape: function (prefix, value, optional = true) {
            //     return this.dmfescape(prefix, value, optional)
            // }
            iconMessage() { return mdiMessageTextOutline },
            iconEmail() { return mdiAt },
            iconSubject() { return mdiTagTextOutline }
        }
    }
</script>
