<template>
    <div>
        <v-btn :block="$vuetify.breakpoint.mdAndUp" class="smfm-primary-btn smfm-lg-btn" :disabled="!currentqr.image"
            :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
            @click="openQRDownloadDialog">
            3. {{ $t('app.download.downloadqr') }}
        </v-btn>
        <!-- QR download dialog -->
        <qr-download-dialog
            :dialogVisible="qrDownloadDialogVisible"
            :qrcodes="[currentqr]"
            :qrlabel="qrlabel"
            @downloadQR="downloadQR"
            @close="qrDownloadDialogVisible=false">
        </qr-download-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import QrDownloadDialog from "@/components/demo/QrDownloadDialog";
    import qrdownload from "@/mixins/qrdownload";

    export default {
        name: "QrDownloadButton",
        components: { QrDownloadDialog },
        mixins: [qrdownload],
        props: {
            qrlabel: null,
        },
        data() {
            return {
                qrDownloadDialogVisible: false,
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
        },
        methods: {
            openQRDownloadDialog() {
                this.qrDownloadDialogVisible = true;
            },
            downloadQR(data) {
                this.qrDownloadDialogVisible = false;
                this.downloadQrCodesDemo(this.qrlabel, data)
                    .then(filename => this.$emit('downloaded', filename))
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
