import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=137d87bc&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=js&"
export * from "./LanguageSelector.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSelector.vue?vue&type=style&index=0&id=137d87bc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137d87bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VHover,VIcon,VList,VListItem,VListItemTitle,VMenu})
