<template>
    <v-dialog
        v-model="dialogVisible"
        scrollable
        max-width="500">
        <v-card class="smfm-dialog-with-header">
            <v-toolbar flat class="smfm-page-subheading">
                {{ header }}
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click.stop="closeDialog()">
                    <v-icon color="secondary">{{ iconClose }}</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-html="content" class="pb-0 popup-content" 
                :class="[$vuetify.breakpoint.smAndDown ? 'pt-5' : 'pt-7']">
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mdiClose } from "@mdi/js";

    export default {
        name: "AboutDynamicPopup",
        data() {
            return {
                type: null,
                dialogVisible: false,
            }
        },
        computed: {
            card() {
                const f = this.dynamiccards[this.currentLang].filter(c => c.shortname === this.type)
                return f.length ? f[0] : {}
            },
            header() { return this.card.exampleheader },
            content() { return this.prepareTextWithLinks(this.card.example) },
            iconClose() { return mdiClose },
        },
        methods: {
            showDialog(type) {
                this.type = type
                this.dialogVisible = true
            },
            closeDialog() {
                this.type = null
                this.dialogVisible = false
            },
        }
    }
</script>

<style scoped lang="scss">
/*! purgecss start ignore */
::v-deep {
    .popup-content {
        font-size: 16px;
        line-height: 24px;
        color: #21252C !important;
        .me-5, .mr-5 {
            min-width: 80px;
            height: 80px;
        }
    }
}
/*! purgecss end ignore */
</style>