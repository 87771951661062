<template>
    <div>
        <v-radio-group v-model="ttype" dense @change="$emit('change')">
            <v-radio v-for="(typelabel, type) in ttypes" dense :label="typelabel" :value="type" :key="'ttype_'+type"></v-radio>
        </v-radio-group>
        <div class="mt-1" v-if="isLink">
            <v-text-field outlined name="profile" label="@handle" v-model="profile"
                          :rules="profileRules" :prepend-icon="iconTwitter" dir="ltr"
                          @change="$emit('change')"></v-text-field>
        </div>
        <div class="mt-1" v-else>
            <v-textarea outlined rows="2" :label="$t('app.forms.tweettext')" name="tweet" v-model="tweet"
                        :rules="tweetRules"
                        :prepend-icon="iconMessage" :counter="280"
                        @change="$emit('change')"></v-textarea>
        </div>
    </div>
</template>

<script>
    import { mdiMessageTextOutline } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";
    import customIcons from "@/mixins/customIcons";

    export default {
        mixins: [ validation, dmf, customIcons ],
        data() {
            return {
                ttype: "link",
                profile: "",
                tweet: "",
                ttypes: {link: this.$t('app.forms.twitterprofile'), tweet: this.$t('app.forms.twittertweet')}
            };
        },
        computed: {
            isLink() {
                return this.ttype === 'link'
            },
            counter() {
                return (''+this.tweet).length
            },
            value: {
                get() {
                    return {
                        ttype: this.ttype,
                        profile: this.profile,
                        tweet: this.tweet,
                    }
                },
                set(newValue) {
                    this.ttype = newValue.ttype
                    this.profile = newValue.profile
                    this.tweet = newValue.tweet
                }
            },
            profileRules() {
                return this.isLink ? [this.requiredRule] : []
            },
            tweetRules() {
                return !this.isLink ? [this.requiredRule, this.maxlengthRule(280)] : []
            },
            qrdata: function() {
                if (this.isLink) {
                    return {
                        data: 'https://twitter.com/' + this.profile.trim().replace(/^@/, ''),
                        label: '@' + this.profile.trim().replace(/^@/, '')
                    }
                } else {
                    return {
                        data: 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.tweet),
                        label: this.tweet
                    }
                }
            },
            iconMessage() { return mdiMessageTextOutline }
        }
    }
</script>

<style scoped>
    .wordcount {
        font-size: 0.8rem;
        color: #777;
    }
    .wordcount input {
        background: none;
        border: none;
        width: 30px;
        text-align: right;
    }
</style>
