<template>
    <div v-if="elements && elements.length" class="mt-5">
        <v-row dense v-for="element in elements" :key="element.name" class="mb-3">
            <v-col cols="12" sm="10" md="8">
                <v-text-field outlined dense :label="element.label" :key="keys[element.name]" :disabled="disabled"
                    v-model="values[element.name]" @change="$emit('change', values)"
                    @input="$emit('input', values)">
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="10" md="8">
                <div v-if="element.hasfontsize">
                    <v-slider class="no-v-messages" v-model="values[element.name+'-fontsize']" :key="keysfs[element.name]" :disabled="disabled"
                        :label="$t('qrstyle.fontsize')" max="300" min="0" hide-details
                        @change="$emit('change', values)" step="1">
                        <template v-slot:append>
                            <v-text-field v-model="values[element.name+'-fontsize']" 
                                hide-details single-line dense style="width: 65px; margin-top: -5px" 
                                @change="$emit('change', values)" suffix="%"/>
                        </template>
                    </v-slider>
                </div>
            </v-col>
        </v-row>
        <v-btn v-if="elements.length" text class="smfm-reset-btn px-0" @click="resetValues" :disabled="disabled">
            <v-icon class="me-2">{{ iconReset }}</v-icon>
            {{ $t('qrstyle.resettodefault') }}
        </v-btn>
    </div>
</template>

<script>
    import { mdiRefresh } from '@mdi/js';
    import templatetext from "@/mixins/templatetext";

    export default {
        name: "QrTemplateCaptions",
        mixins: [ templatetext ],
        props: {
            value: { type: Object },
            noneditable: { default: false },
            // Data can be passed as "templateimage" object or as a "templateimagedom" XML DOM element (from the editor).
            templateimage: null,
            templateimagedom: null,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                elements: [],
                values: {},
                keys: {},
                keysfs: {},
            }
        },
        computed: {
            iconReset() { return mdiRefresh },
        },
        created() {
            this.values = this.value
            this.parseTemplate()
        },
        methods: {
            parseTemplate() {
                if (this.templateimage && this.templateimage.config && Array.isArray(this.templateimage.config)) {
                    this.elements = this.templateimage.config
                } else {
                    this.elements = this.parseTemplateTexts()
                }
                if (this.noneditable) {
                    // Always reset values when template changed.
                    this.values = {}
                }

                if (this.elements) {
                    for (let el of this.elements) {
                        if (!(el.name in this.values)) {
                            this.values[el.name] = el.default
                        }
                        this.keys[el.name] = this.keys[el.name] ? (this.keys[el.name]+1) : 1
                        if (el.hasfontsize && !(`${el.name}-fontsize` in this.values)) {
                            this.values[`${el.name}-fontsize`] = 100
                        }
                        this.keysfs[el.name] = this.keysfs[el.name] ? (this.keysfs[el.name]+1) : 1
                    }
                    this.$emit('input', this.values)
                }
            },
            parseTemplateTexts() {
                if (!this.templateimagedom) {
                    return []
                }
                let texts = this.templateimagedom.querySelectorAll('text[id]')
                let els = []
                for (let text of texts) {
                    const name = text.getAttribute('id')
                    // label = text.getAttribute('data-label')
                    els.push({
                        name,
                        //label: typeof label === 'undefined' || !label ? name : label,
                        label: name,
                        default: text.textContent,
                        hasfontsize: !!text.hasAttribute('font-size')
                    })
                }
                return els
            },
            resetValues() {
                this.values = {}
                this.parseTemplate()
                this.$emit('change', this.values)
            },
        },
        watch: {
            templateimage() {
                this.parseTemplate()
            },
            templateimagedom() {
                this.parseTemplate()
            },
            value(val) {
                // Update the input when the v-model of the element was changed from the outside.
                this.values = val
            },
        }
    }
</script>

<style scoped lang="scss">
.no-v-messages label {
    color: #909295;
}
</style>