<template>
    <v-dialog v-model="dialog" :max-width="maxWidth" v-bind="$attrs">
        <template v-slot:activator="{}">
            <slot name="activator" :open="open"></slot>
        </template>
        <v-card class="info-dialog">
            <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 60 : 70">
                <div class="d-flex justify-start align-center">
                    <v-icon v-if="!header" class="me-3">{{ iconInfo }}</v-icon>
                    <span>{{ header ? header : $t('app.info') }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn v-if="showClose" icon @click="close">
                    <v-icon color="secondary">{{ iconClose }}</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="secondary--text">
                <div v-if="!noTitle" class="smfm-page-heading mb-3">
                    {{ title ? title : $t('pro.confirm') }}
                </div>
                <slot></slot>
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-center pt-5"
                :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
                <v-btn v-if="showCancel" text color="secondary" class="me-3" @click="close">
                    {{ $t('app.cancel') }}
                </v-btn>
                <slot name="buttons" :save="save" :close="close">
                    <v-btn @click="save">{{buttontext}}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mdiClose, mdiInformationOutline } from "@mdi/js";

    export default {
        name: "SimpleDialog",
        props: {
            header: null,
            title: null,
            noTitle: {default: false},
            buttontext: null,
            showCancel: {default: true},
            maxWidth: {default: "500"},
            showClose: {default: true},
        },
        data() {
            return {
                dialog: false,
                wrapper: false,
            }
        },
        computed: {
            iconClose() { return mdiClose },
            iconInfo() { return mdiInformationOutline },
            value: {
                get() {
                    return this.dialog
                },
                set(v) {
                    this.dialog = v
                }
            }
        },
        methods: {
            open() {
                this.dialog = true
            },
            close() {
                this.dialog = false
            },
            save() {
                this.close()
                this.$emit('save')
            },
            isOpen() {
                return this.dialog
            }
        },
    }
</script>

<style scoped lang="scss">
.info-dialog {
    ::v-deep {
        a {
            text-decoration: none !important;
        }
    }
}
</style>