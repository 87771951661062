<template>
    <div>
        <div class="demo-tabs-wrap">
            <v-tabs ref="tabs" centered center-active icons-and-text hide-slider 
                mobile-breakpoint="1264" class="smfm-demo-tabs">
                <v-tab v-for="(data, index) in democards" :key="index" :to="{name: 'langandslug', params: {lang: currentLang, slug: data.slug}}"
                    class="d-flex" :class="[$vuetify.breakpoint.mdAndDown ? 'flex-row' : 'flex-column']"
                    :ripple="false">
                    <div class="d-flex justify-center align-center"
                        :class="[$vuetify.breakpoint.mdAndDown ? 'mb-0 me-2' : 'icon-wrap']">
                        <v-icon>{{ getQrIcon('static', data.shortname) }}</v-icon>
                    </div>
                    <span class="text-body-1 text-capitalize mb-0">{{ data.title }}</span>
                </v-tab>
            </v-tabs>
        </div>
        <v-container v-cloak :class="{'px-0': $vuetify.breakpoint.smAndDown}">
            <v-row>
                <v-col md="8" cols="12" class="demo-cards ps-0">
                    <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="qr-types">
                            <v-tabs-items v-model="currenttab">
                                <v-tab-item v-for="(data, index) in democards" :key="index">
                                    <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                                        :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'px-10 py-7']" class="mb-4">
                                        <h1 class="smfm-page-heading font-weight-medium mb-3">
                                            <span v-if="hasform">1.&nbsp;</span><span v-html="data.pageheader"></span>
                                        </h1>
                                        <div v-html="prepareTextWithLinks(data.aboveform)" class="mb-7 aboveform"
                                             :key="`aboveform_${index}_${resourcesTocLoaded}`"
                                            :class="[$vuetify.breakpoint.smAndDown ? 'text-body-2' : 'text-body-1']">
                                        </div>
                                        <pseudo-form :ref="'qrform'+data.shortname">
                                            <component v-bind:is="'demo-form-' + data.shortname" :ref="'element'+data.shortname" @change="autoSubmit">
                                            </component>
                                        </pseudo-form>
                                        <div v-if="data.description" class="description" v-html="prepareTextWithLinks(data.description)"
                                             :key="`description_${index}_${resourcesTocLoaded}`"
                                             :class="[$vuetify.breakpoint.smAndDown ? 'text-body-2' : 'text-body-1']">
                                        </div>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                            <!-- QR styles -->
                            <v-card v-if="hasform" flat :tile="$vuetify.breakpoint.smAndDown"
                                :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'px-10 py-7']">
                                <h2 class="smfm-page-subheading font-weight-medium mb-4">
                                    2.&nbsp;<span>{{ $t('app.setupstyle') }}</span>
                                </h2>
                                <qrstyles @change="autoSubmit" ref="qrstyles" v-show="hasform" :expanded="0"></qrstyles>
                            </v-card>
                    </v-card>
                </v-col>
                <v-col md="4" cols="12" class="demo-cards pe-0">
                    <div class="demo-image-container smfm-sticky-md-100">
                        <qr-preview ref="qrpreview" :iconname="democards[currenttab].shortname" class="mb-4">
                        </qr-preview>
                        <qr-download-button v-if="hasform" :qrlabel="qrlabel"></qr-download-button>
                    </div>
                </v-col>
            </v-row>
            <div style="flex: 1 1 auto;position: relative">
                <div>
                    <v-card flat v-if="democards[currenttab].footer" class="pa-5 mt-5 d-flex flex-column align-center smfm-static-footer">
                        <div v-html="prepareTextWithLinks(democards[currenttab].footer)" :key="`footer_${currenttab}_${resourcesTocLoaded}`"></div>
                    </v-card>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import EventBus from '@/plugins/EventBus.js';
    import qrMixin from "@/mixins/qrMixin";
    import QrDownloadButton from "@/components/demo/QrDownloadButton";
    import QrPreview from "@/components/demo/QrPreview";
    import PseudoForm from "@/components/forms/PseudoForm";
    import Qrstyles from "@/components/forms/Qrstyles";
    import titleMixin from "@/mixins/titleMixin";
    import customIcons from "@/mixins/customIcons";

    export default {
        name: "DemoGenerator",
        components: { Qrstyles, PseudoForm, QrPreview, QrDownloadButton },
        mixins: [ qrMixin, titleMixin, customIcons ],
        mounted() {
            // Build a list of all existing Vue components (such as "url", "whatsapp", "phone", "facebook", etc)
            for (var key in this.$options.components) this.components.push(key)
            this.components = this.components.filter(key => key.match(/^DemoForm/)).map(key => key.substr(8).toLowerCase())

            // Scan current URL and detect the active tab. If there is no active tab, assume first defined tab ("url").
            this.setCurrentTab() || this.$router.replace('/'+this.routeLang+'/'+this.democards[this.currenttab].slug)
        },
        data() {
            return {
                currenttab: 0,
                components: [],
                lastvalue: {},
                qrlabel: '',
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            ...mapGetters(['resourcesTocLoaded']),
            routeLang: function() {
                // When we switch from one language to another, the currentLang may not be updated immediately
                // because the language files are loaded from server.
                return (this.$route && this.$route.params && this.$route.params.lang) ? this.$route.params.lang : this.currentLang
            },
            democards: function() {
                return (this.routeLang in this.staticcards) ? this.staticcards[this.routeLang] : this.staticcards['en']
            },
            currentdata: function() {
                return this.democards[this.currenttab]
            },
            hasform: function() {
                return this.currentdata.hasform
            },
        },
        methods: {
            ...mapActions(['resetCurrentQr']),
            displayQr() {
                if (this.lastvalue[this.currenttab]) {
                    const value = this.lastvalue[this.currenttab]
                    this.refreshQrDemo(value.data, this.$refs.qrstyles, this.currentdata.shortname)
                    this.qrlabel = value ? ((this.currentdata.qrprefix ? this.currentdata.qrprefix : this.currentdata.title) +
                        ": " + value.label) : ''
                } else {
                    this.resetCurrentQr()
                    this.qrlabel = ''
                }
            },
            autoSubmit() {
                this.$nextTick(() => {
                    const form = this.$refs['qrform'+this.currentdata.shortname][0];
                    const eventStartDate = this.currentdata.shortname === 'event' ? 
                        form.$refs.formelement.$children[0].$refs.startdate.value : null;
                    const eventEndDate = this.currentdata.shortname === 'event' ?
                        form.$refs.formelement.$children[0].$refs.enddate.value : null;
                    if (form.form.validate()) {
                        if (this.currentdata.shortname === 'event' && (eventStartDate === null || eventEndDate === null)) {
                            if (eventStartDate === null) EventBus.$emit('eventStartDateError');
                            if (eventEndDate === null) EventBus.$emit('eventEndDateError');
                            this.$vuetify.goTo(0);
                            return;
                        } else {
                            this.lastvalue[this.currenttab] = this.$refs['element'+this.currentdata.shortname][0].qrdata;
                            this.displayQr();
                            form.submit();
                        }
                    } else {
                        this.$vuetify.goTo(0);
                    }
                })
            },
            setCurrentTab() {
                const findCard = (el) => el && (this.compareSlugs(el.slug) || this.compareSlugs(el.aliases))
                let selected = Object.keys(this.democards).filter(a => this.$route.name === 'langandslug' && findCard(this.democards[a])).concat([0])[0]
                this.currenttab = parseInt(selected)
                this.displayQr()
                // Return true if the URL matches the selected tab.
                if (this.$route.name === 'langandslug' && this.compareSlugs(this.currentdata.slug)) {
                    this.setTitle(this.currentdata.metatitle ? this.currentdata.metatitle : this.currentdata.pageheader)
                    this.setMetaDesc(this.currentdata.metadesc)
                    if (this.currenttab === 0) this.setOgUrl(`/${this.routeLang}`)
                    return true
                }
                this.setTitle()
                return false
            },
        },
        watch: {
            $route() {
                this.setCurrentTab() || this.$router.replace('/'+this.routeLang+'/'+this.democards[this.currenttab].slug)
            },
        }
    }
</script>

<style scoped lang="scss">
.demo-tabs-wrap {
    background-color: #fff;
}
.smfm-demo-tabs {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    .icon-wrap {
        width: 44px;
        height: 44px;
        background: transparent;
        border-radius: 10px;
        margin-bottom: 6px;
    }
    .v-tab {
        color: #393E46 !important;
        &:before {
            background-color: transparent;
        }
        &:hover {
            .v-icon {
                color: #393E46;
            }
        }
        &.v-tab--active {
            color: #03949A !important;
            .icon-wrap {
                background: #12C7D0;
                .v-icon {
                    color: #fff;
                }
            }
        }
    }
    @media (max-width: 1263px) {
        .v-tab {
            border: 1px solid #C7C8CA;
            border-radius: 10px;
            padding: 8px 20px;
            margin: 0 5px !important;
            &.v-tab--active {
                background: #00ADB5;
                border-color: #00ADB5;
                .text-body-1, .v-icon {
                    color: #fff;
                }
            }
        }
    }
}
.demo-cards {
    padding: 0 8px;
    @media(max-width: 960px) {
        padding: 0;
        &:first-child {
            padding-bottom: 16px;
        }
    }
}
.qr-types {
    background-color: transparent;
    .v-tabs-items {
        background-color: transparent;
    }
}
::v-deep {
    .smfm-static-footer {
        color: #393E46;
        h1 {
            font-size: 28px;
            line-height: 34px;
            margin: 40px 0 16px;
        }
        h2 {
            font-size: 24px;
            line-height: 32px;
            margin: 40px 0 16px;
        }
        h3 {
            font-size: 18px;
            margin: 32px 0 16px;
        }
        a {
            color: #3271D9;
            text-decoration: none;
        }
        .imageholder {
            padding: 16px;
            background: #F6F6F6;
            margin-bottom: 24px;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
        ul {
            padding-inline-start: 32px;
            margin-bottom: 40px;
        }
    }
}

</style>
