<template>
    <div>
        <v-form target="rememberformvalues" action="about:blank" ref="formelement" v-model="valid">
            <div class="d-none"><input type="submit" ref="submitelement"></div>
            <slot></slot>
        </v-form>
        <iframe id="rememberformvalues" name="rememberformvalues" class="d-none" src="about:blank"></iframe>
    </div>
</template>

<script>
    export default {
        name: "PseudoForm",
        data() {
            return {
                valid: true,
            }
        },
        computed: {
            form() {
                return this.$refs.formelement
            },
        },
        methods: {
            submit() {
                // Submit the form to about:blank so that browser remembers the values in the autofill.
                this.$refs.submitelement.click()
            }
        }
    }
</script>

<style scoped>

</style>
