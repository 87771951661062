<template>
    <div>
        <div>
            <v-text-field outlined name="eventname" :label="$t('app.forms.eventname')" v-model="name"
                :rules="[requiredRule]"  :prepend-icon="iconSubject"
                @change="$emit('change')">
            </v-text-field>
        </div>
        <v-row>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                <div class="d-flex align-start date-time-picker">
                    <div class="v-input__prepend-outer mt-4">
                        <v-icon :class="{
                            'primary--text': isStartDateFocused,
                            'error--text': startDateNotValid || isStartDateError
                        }"> {{ dateIcon }}
                        </v-icon>
                    </div>
                    <div class="v-input theme--light v-text-field"
                        :class="{
                            'v-input--is-focused': isStartDateFocused,
                            'error--text': startDateNotValid || isStartDateError
                        }">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                    <label class="v-label theme--light v-label--active"
                                        :class="{
                                            'primary--text': isStartDateFocused,
                                            'error--text': startDateNotValid || isStartDateError
                                        }"> {{ $t('app.forms.startdatetime') }}
                                    </label>
                                    <date-picker v-model="startdate" type="datetime" dir="ltr"
                                        ref="startdate"
                                        :show-second="false"
                                        value-type="format" format="YYYY-MM-DD HH:mm"
                                        :disabled-date="disabledBeforeToday"
                                        @focus="isStartDateFocused = true"
                                        @blur="onStartDateBlur"
                                        @input="$emit('change')">
                                    </date-picker>
                                </div>
                            </div>
                            <div class="v-text-field__details">
                                <div class="v-messages theme--light error--text">
                                    <div v-if="startDateNotValid || isStartDateError" class="v-messages__wrapper">
                                        {{ $t('app.validation.fieldrequired') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                <div class="d-flex align-start date-time-picker">
                    <div class="v-input__prepend-outer mt-4">
                        <v-icon :class="{
                            'primary--text': isEndDateFocused,
                            'error--text': endDateNotValid || isEndDateError
                        }"> {{ dateIcon }}
                        </v-icon>
                    </div>
                    <div class="v-input theme--light v-text-field"
                        :class="{
                            'v-input--is-focused': isEndDateFocused,
                            'error--text': endDateNotValid || isEndDateError
                        }">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                    <label class="v-label theme--light v-label--active"
                                        :class="{
                                            'primary--text': isEndDateFocused,
                                            'error--text': endDateNotValid || isEndDateError
                                        }"> {{ $t('app.forms.enddatetime') }}
                                    </label>
                                    <date-picker v-model="enddate" type="datetime" dir="ltr"
                                        ref="enddate"
                                        :show-second="false"
                                        value-type="format" format="YYYY-MM-DD HH:mm"
                                        :disabled-date="disabledBeforeDateFrom"
                                        @focus="isEndDateFocused = true"
                                        @blur="onEndDateBlur"
                                        @input="$emit('change')">
                                    </date-picker>
                                </div>
                            </div>
                            <div class="v-text-field__details">
                                <div class="v-messages theme--light error--text">
                                    <div v-if="endDateNotValid | isEndDateError" class="v-messages__wrapper">
                                        {{ $t('app.validation.fieldrequired') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div>
            <v-text-field outlined name="location" :label="$t('app.forms.location')" v-model="location"
                :prepend-icon="iconAddress"
                @change="$emit('change')">
            </v-text-field>
        </div>
        <div>
            <v-radio-group v-model="tztype" dense @change="$emit('change')">
                <v-radio v-for="(typelabel, type) in tztypes" dense :label="typelabel" :value="type" :key="'ttype_'+type"></v-radio>
            </v-radio-group>
        </div>
    </div>
</template>

<script>
    // Uses datepicker: https://www.npmjs.com/package/vue2-datepicker
    // VEVENT rfc: https://tools.ietf.org/html/rfc5545 , wiki: https://en.wikipedia.org/wiki/ICalendar
    import { mdiCalendar, mdiClockOutline, mdiMapMarkerOutline, mdiTagTextOutline } from '@mdi/js';
    import EventBus from '@/plugins/EventBus.js';
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";

    export default {
        mixins: [ validation, dmf ],
        components: {
            'DatePicker': () => {
                import(/* webpackChunkName: "datepickercss" */ 'vue2-datepicker/index.css')
                return import(/* webpackChunkName: "datepicker" */ 'vue2-datepicker')
            }
        },
        data() {
            return {
                name: '',
                location: '',
                startdate: null,
                enddate: null,
                tztype: 'notz',
                tztypes: {notz: this.$t('app.forms.eventnotimezone'), 'UTC': this.$t('app.forms.eventutc')},
                isStartDateFocused: false,
                isStartDateTouched: 0,
                isStartDateError: false,
                isEndDateFocused: false,
                isEndDateTouched: 0,
                isEndDateError: false,
            };
        },
        computed: {
            dateIcon() { return mdiCalendar },
            timeIcon() { return mdiClockOutline },
            startDateNotValid() {
                return this.isStartDateTouched > 0 && !this.startdate;
            },
            endDateNotValid() {
                return this.isEndDateTouched > 0 && !this.enddate;
            },
            value: {
                get() {
                    return {
                        name: this.name,
                        location: this.location,
                        startdate: this.startdate,
                        enddate: this.enddate,
                        tztype: this.tztype,
                    }
                },
                set(newValue) {
                    this.name = newValue.name
                    this.location = newValue.location
                    this.startdate = new Date(Date.parse(newValue.startdate))
                    this.enddate = new Date(Date.parse(newValue.enddate))
                    this.tztype = newValue.tztype
                }
            },
            qrdata() {
                let data = "BEGIN:VEVENT\nSUMMARY:" + this.name
                if ((''+this.location).length) {
                    data = data + "\nLOCATION:" + this.location
                }
                data = data + "\nDTSTART:" + this.formatdate(this.startdate) +
                    "\nDTEND:" + this.formatdate(this.enddate) +
                    "\nEND:VEVENT"

                return {
                    data: data,
                    label: this.name
                }
            },
            iconSubject() { return mdiTagTextOutline },
            iconAddress() { return mdiMapMarkerOutline},
            minDate() {
                return new Date().toISOString()
            }
        },
        methods: {
            disabledBeforeToday(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            disabledBeforeDateFrom(date) {
                const startDate = new Date(this.startdate);
                startDate.setHours(0, 0, 0, 0);
                return date < startDate;
            },
            formatdate(value) {
                let d = Date.parse(value), date = d ? new Date(d) : null
                if (!d) return ''
                if (this.tztype === 'UTC') {
                    return date.toISOString().replace(/\.\d\d\dZ$/g, 'Z').replace(/[-:]/g, '')
                }

                // Local date-time:
                return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString().replace(/\.\d\d\dZ$/g, '').replace(/[-:]/g, '');
            },
            onStartDateBlur() {
                this.isStartDateTouched++;
                this.isStartDateFocused = false;
            },
            onEndDateBlur() {
                this.isEndDateTouched++;
                this.isEndDateFocused = false;
            }
        },
        mounted() {
            EventBus.$on('eventStartDateError', () => this.isStartDateError = true);
            EventBus.$on('eventEndDateError', () => this.isEndDateError = true);
        },
        beforeDestroy() {
            EventBus.$off('eventStartDateError');
            EventBus.$off('eventEndDateError');
        },
        watch: {
            startdate(value) {
                if (value) this.isStartDateError = false;
            },
            enddate(value) {
                if (value) this.isEndDateError = false;
            },
        },
    }
</script>

<style lang="scss">
.date-time-picker {
    .v-input.v-text-field {
        padding-top: 0;
        margin-top: 0;
        &.v-input--is-focused {
            .v-input__slot {
                border: 2px solid #03949a;
            }
        }
        &.error--text {
            .v-input__slot {
                border: 2px solid #c9454d;
            }
        }
        .v-input__slot {
            height: 56px;
            padding: 0 12px;
            border: 1px solid #C7C8CA;
            border-radius: 4px;
            &:before, &:after {
                border-width: 0 !important;
            }
            .v-label--active {
                position: absolute;
                top: 12px;
                background: #fff;
                padding: 0 3px;
                z-index: 4;
            }
            .mx-datepicker {
                width: 100%;
                height: 56px;
                .mx-input-wrapper {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    margin-right: -12px;
                }
                .mx-input {
                    border: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    font-size: 16px;
                }
                .mx-icon-calendar {
                    display: none;
                }
            }
        }
        .v-text-field__details {
            padding: 0 12px;
            margin-bottom: 8px;
            .v-messages__wrapper {
                line-height: 1;
            }
        }
    }
}
.mx-datepicker-popup {
    z-index: 4 !important;
}
</style>
