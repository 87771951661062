<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    max-width="500">
    <v-card class="smfm-dialog-with-header">
      <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 60 : 70"
        class="smfm-page-subheading">
        {{ $t('app.download.downloadqr') }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.stop="closeDialog()">
          <v-icon color="secondary">{{ iconClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- Format -->
        <div class="mb-5">
          <div class="mb-1 secondary--text">{{ $t('app.download.format') }}:</div>
          <div class="d-flex justify-start align-center flex-wrap">
            <qr-download-dialog-btn
              v-for="(format, index) in formats" :key="index"
              :label="format.value"
              :available="format.available"
              :class="{'selected': formatSelected === format.value }"
              @select="selectFormat($event)">
            </qr-download-dialog-btn>
          </div>
        </div>
        <!-- Size -->
        <div class="mb-1 secondary--text">{{ $t('app.download.qrsize') }}:</div>
        <div class="d-flex justify-start align-center flex-wrap">
          <qr-download-dialog-btn
            v-for="(size, index) in sizes" :key="index"
            :label="size.value"
            :available="size.available"
            :class="{'selected': sizeSelected === +size.value }"
            @select="selectSize($event)">
          </qr-download-dialog-btn>
          <v-btn small text
            v-if="!customSizeInputVisible"
            class="smfm-custom-size-btn"
            @click="showCustomSizeInput()">
            {{ $t('app.download.custom') }}
          </v-btn>
          <div v-if="customSizeInputVisible" class="smfm-custom-size-input">
            <v-text-field
              dense outlined
              hide-details dir="ltr"
              :label="$t('app.download.custom')"
              v-model="customSize"
              :rules="[requiredRule]"
              @input="setCustomSize()">
            </v-text-field>
          </div>
        </div>
        <!-- Advanced settings -->
        <div class="smfm-advanced-settings">
          <v-checkbox v-model="isAdvanced" :readonly="true" hide-details>
            <template v-slot:label>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div class="d-flex justify-start align-center">
                      {{ $t('app.download.advancedsettings') }}
                      <v-icon class="ms-2">
                        {{ iconLock }}
                      </v-icon>
                    </div>
                    <div class="text-caption secondary--text">{{ $t('app.download.advancedoptions') }}</div>
                  </div>
                </template>
                <span>{{ $t('app.download.availableinpro') }}</span>
              </v-tooltip>
            </template>
          </v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pt-5"
          :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
        <v-btn
          text
          class="me-3"
          color="secondary"
          @click.stop="closeDialog()"
        > {{ $t('app.cancel') }}
        </v-btn>
        <v-btn
          class="smfm-primary-btn smfm-lg-btn"
          @click="onDownloadClick()"
        > {{ $t('app.download.download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import { mdiClose, mdiLock } from '@mdi/js';
    import validation from "@/mixins/validation";
    import QrDownloadDialogBtn from "@/components/demo/QrDownloadDialogBtn";
    export default {
        name: "QrDownloadDialog",
        components: { QrDownloadDialogBtn },
        mixins: [ validation ],
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            qrlabel: null,
            qrcodes: {default: []}, // list of codes to download
        },
        data() {
            return {
                formatSelected: 'png',
                sizeSelected: 256,
                customSize: 640,
                customSizeInputVisible: false,
                qrdpi: 150,
                isAdvanced: false,
            }
        },
        computed: {
            iconClose() { return mdiClose },
            iconLock() { return mdiLock },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }
            },
            formats() {
                return [
                    { value: 'png', available: true },
                    { value: 'svg', available: true },
                    { value: 'pdf', available: this.isPro },
                    { value: 'ps', available: this.isPro },
                ]
            },
            sizes() {
                return [
                    { value: '128', available: true },
                    { value: '256', available: true },
                    { value: '512', available: true },
                    { value: '1024', available: true },
                ]
            },
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
            },
            selectFormat(format) {
                this.formatSelected = format;
            },
            selectSize(size) {
                this.sizeSelected = Number(size);
                this.customSizeInputVisible = false;
            },
            showCustomSizeInput() {
                this.customSizeInputVisible = true;
                this.sizeSelected = this.customSize;
            },
            setCustomSize() {
                this.sizeSelected = this.customSize;
            },
            onDownloadClick() {
                if (this.sizeSelected) {
                    this.$emit('downloadQR', {
                        format: this.formatSelected,
                        dpi: this.qrdpi,
                        size: this.sizeSelected,
                        codes: this.qrcodes
                    });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>