<template>
    <div>
        <demo-pro-version-block
            v-if="!isPro"
            :text="$t('app.withproversionfile')"
            @seeExample="$refs.exampledialog.showDialog('page')">
        </demo-pro-version-block>
        <about-dynamic-popup ref="exampledialog"></about-dynamic-popup>
    </div>
</template>

<script>
    import DemoProVersionBlock from "@/components/demo/DemoProVersionBlock";
    import AboutDynamicPopup from "@/components/about/AboutDynamicPopup";

    export default {
        name: "DemoFormFile",
        components: {AboutDynamicPopup, DemoProVersionBlock },
    }
</script>

<style scoped>

</style>