<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" class="pa-0">
                <v-text-field outlined name="phone" :label="$t('app.forms.enterphone')" v-model="phone"
                            :rules="[requiredRule]" :prepend-icon="iconPhone" dir="ltr"
                            @change="$emit('change')"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-textarea outlined rows="4" :label="$t('app.forms.message')" name="message" v-model="message"
                            :prepend-icon="iconMessage"
                            @change="$emit('change')"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mdiMessageTextOutline, mdiPhone } from "@mdi/js";
    import validation from "@/mixins/validation";
    import dmf from "@/mixins/dmf";

    export default {
        mixins: [ validation, dmf ],
        data() {
            return {
                phone: "",
                message: "",
            };
        },
        computed: {
            qrdata() {
                const message = '' + this.message
                return {
                    data: this.df('SMSTO', this.escape_phone(this.phone), message.length === 0 ? ' ' : message),
                    label: this.phone
                }
            },
            value: {
                get() {
                    return {
                        phone: this.phone,
                        message: this.message,
                    }
                },
                set(newValue) {
                    this.phone = newValue.phone
                    this.message = newValue.message
                }
            },
            iconMessage() { return mdiMessageTextOutline },
            iconPhone() { return mdiPhone }
        }
    }
</script>
