<template>
    <div class="language-selector">
        <v-menu offset-y v-if="!isMobileMenu">
            <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                    <v-btn icon v-bind="attrs" v-on="on"
                        class="text-uppercase me-5 lang-code-btn" :class="{ 'hover': hover }">
                        {{ currentLang }}
                        <v-icon color="white">
                            {{ attrs["aria-expanded"] === "true" ? iconChevronUp : iconChevronDown }}
                        </v-icon>
                    </v-btn>
                </v-hover>
            </template>
            
            <v-list dense width="160">
                <v-list-item v-for="link in links" :key="link.code"
                    :class="{'selected': link.code === currentLang}">
                    <v-list-item-title class="text-body-1 font-weight-medium">
                        <router-link :to="link.url" class="popuplink">{{ link.name }}</router-link></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-expansion-panels v-else class="lang-panel">
            <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 font-weight-medium white--text">
                    {{ currentLangName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list nav dense>
                        <v-list-item v-for="link in links" :key="link.code">
                            <v-list-item-title>
                                <router-link :to="link.url" class="mobilelink" :class="{'selected': link.code === currentLang}">
                                    {{ link.name }}
                                </router-link>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

    export default {
        props: {
            isMobileMenu: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            iconChevronDown() { return mdiChevronDown },
            iconChevronUp() { return mdiChevronUp },
            currentLangName() {
                return this.languagesMenu[this.currentLang];
            },
            links() {
                let links = []
                let alternatives = this.getAlternatives(false)
                for (let code in alternatives) {
                    links.push({code, name: this.languagesMenu[code], url: alternatives[code]})
                }
                return links
            }
        },
    }
</script>

<style scoped lang="scss">
a.popuplink {
    text-decoration: none;
    color: inherit;
}
.language-selector {
    .lang-code-btn {
        color: #12C7D0 !important;
        &.hover {
            color: #fff !important;
        }
    }
    .v-expansion-panels {
        border-radius: 0;
        .v-expansion-panel {
            .v-list-item {
                &:before {
                    background-color: transparent !important;
                }
                a.mobilelink {
                    text-decoration: none;
                    color: #909295;
                    padding: 0 18px;
                    font-size: 16px;
                    &.selected {
                        color: #00ADB5;
                    }
                    &:hover {
                        color: #C7C8CA;
                    }
                }
            }
        }
    }
}
</style>
